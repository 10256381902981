























import Vue from 'vue';
export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
});
