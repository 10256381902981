























































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Toggle from '@/components/Presenter/Toggle.vue';

export default Vue.extend({
  components: { Toggle },
  data() {
    return {
      enabled: false,
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'preferences/getUserPreferences',
    }),
    items() {
      return [
        {
          id: 'session_reports',
          name: this.$t('views.presenter.preferences.emailTitleReports'),
          value: this.userPreferences
            ? this.userPreferences[0]['session_reports']
            : false,
        },
        {
          id: 'weekly_reports',
          name: this.$t('views.presenter.preferences.emailTitleSummaries'),
          value: this.userPreferences
            ? this.userPreferences[0]['weekly_reports']
            : false,
        },
        {
          id: 'marketing',
          name: this.$t('views.presenter.preferences.emailTitleMarketing'),
          value: this.userPreferences
            ? this.userPreferences[0]['marketing']
            : false,
        },
      ];
    },
  },
  methods: {
    updateMailPreference(key: string, value: boolean) {
      const userId = this.$route.params.uid;
      this.$store.dispatch('preferences/updateMailPreference', {
        userId,
        key,
        value,
      });
    },
  },
});
